var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.paymentTermList,"items-per-page":-1,"hide-default-footer":"","fixed-header":"","height":_vm.list_height + 'px'},on:{"dblclick:row":function ($event, ref) {
	var item = ref.item;

	return _vm.rowClick(item);
}},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
var item = ref.item;
return [(item.amount)?_c('div',[_c('div',[_vm._v(" "+_vm._s(item.amount.toLocaleString() + (item.is_under_amount ? " 未満" : item.is_under_amount == null ? "" : " 以上"))+" ")])]):_c('div',[_vm._v(_vm._s("ー"))])]}},{key:"item.payment_month",fn:function(ref){
var item = ref.item;
return [(item.payment_month == 1)?_c('div',[_vm._v(" "+_vm._s("翌月")+" ")]):_vm._e(),(item.payment_month == 2)?_c('div',[_vm._v(" "+_vm._s("翌々月")+" ")]):_vm._e(),(!item.payment_month)?_c('div',[_vm._v(_vm._s("ー"))]):_vm._e()]}},{key:"item.payment_day",fn:function(ref){
var item = ref.item;
return [(item.payment_day == 99)?_c('div',[_vm._v(" "+_vm._s("末日")+" ")]):_vm._e(),(item.payment_day != 99)?_c('div',[_vm._v(" "+_vm._s(item.payment_day)+" ")]):_vm._e(),(!item.payment_day)?_c('div',[_vm._v(_vm._s("ー"))]):_vm._e()]}},{key:"item.add_day",fn:function(ref){
var item = ref.item;
return [(item.add_day)?_c('div',[_vm._v(" "+_vm._s(item.add_day)+" ")]):_vm._e(),(!item.add_day)?_c('div',[_vm._v(_vm._s("ー"))]):_vm._e()]}},{key:"item.other_terms",fn:function(ref){
var item = ref.item;
return [(item.other_terms)?_c('div',[_c('v-tooltip',{attrs:{"top":"","color":"rgba(0, 0, 0, 1)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"blue--text"},'div',attrs,false),on),[_vm._v("●")])]}}],null,true)},[_vm._v(" "+_vm._s(item.other_terms)+" ")])],1):_c('div',[_vm._v(" "+_vm._s("ー")+" ")])]}}],null,true)}),_c('div',{staticClass:"text-center pt-3",attrs:{"id":"pagination"}},[_c('v-pagination',{attrs:{"length":_vm.pagination.last_page},on:{"input":function($event){return _vm.paramsToQuery()}},model:{value:(_vm.params.page),callback:function ($$v) {_vm.$set(_vm.params, "page", $$v)},expression:"params.page"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
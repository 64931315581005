
import { Component, Watch } from "vue-property-decorator";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import { Action, Getter, Mutation } from "vuex-class";
import { IPaymentTermListRequest, IPaymentTerm } from "@/types/payment_term";
import { IPagination } from "@/types";

@Component({})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  // payment_term
  @Action("payment_term/adminGetList")
  public getPaymentTermList!: (request: IPaymentTermListRequest) => boolean;

  @Getter("payment_term/list")
  public paymentTermList!: IPaymentTerm[];

  @Getter("payment_term/pagination")
  public pagination!: IPagination<IPaymentTerm[]>;

  @Mutation("payment_term/clear")
  public clearPaymentTerm!: () => void;

  // watch
  @Watch("$route.query")
  public async changeQuery() {
    this.queryToParams();
    await this.getPaymentTermList(this.params);
  }

  // パラメータ定義
  public params: IPaymentTermListRequest = {
    page: 1,
    customer_id: 0
  };

  //
  // variables
  //
  public headers = [
    { text: "支払方法", value: "payment_method_name", sortable: false },
    { text: "金額上下限", value: "amount", sortable: false, align: "end" },
    { text: "支払月", value: "payment_month", sortable: false },
    { text: "支払日", value: "payment_day", sortable: false },
    { text: "加算日", value: "add_day", sortable: false },
    { text: "その他条件", value: "other_terms", sortable: false }
  ];

  //
  // methods
  //
  private async created() {
    this.clearPaymentTerm();
    this.queryToParams();
    await this.getPaymentTermList(this.params);
  }

  // urlからパラメータをセットする
  private queryToParams() {
    const routeQuery = this.$route.query;
    const routeParams = this.$route.params;
    this.params = {
      page: routeQuery.page ? Number(routeQuery.page) : 1,
      customer_id: routeParams.customer_id ? Number(routeParams.customer_id) : 0
    };
  }

  // 1ページ目から検索
  // 主に検索条件が変更されたときに利用
  public async searchFirst() {
    this.params.page = 1;
    await this.paramsToQuery();
  }

  // URLのパラメータをセットする
  private paramsToQuery() {
    this.$router
      .push({
        path: this.$router.currentRoute.path,
        query: {
          page: this.params.page ? String(this.params.page) : "1"
        }
      })
      .catch(() => {});
  }

  // 行をクリックした際の挙動
  public rowClick(payment_term: IPaymentTerm) {
    this.$router.push(`/admin/payment_term/${payment_term.id}/edit`);
  }
}
